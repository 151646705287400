import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { EditEstimateDetails, EstimateId } from '../../models/estimate/estimate';
import { EstimateDetailsMapper } from '../mappers/estimate/estimate-details.mapper';
import { BulkUpdateOption } from '../../models/bulk-update-option';

import { AppUrlsConfig } from './app-urls.config';

/** Common estimate details. */
@Injectable({
  providedIn: 'root',
})
export class CommonEstimateApiService {

  private readonly apiUrls = inject(AppUrlsConfig);

  private readonly http = inject(HttpClient);

  private readonly estimateDetailsMapper = inject(EstimateDetailsMapper);

  /**
   * Update estimate details.
   * @param estimateId Estimate id.
   * @param updatedEstimateDetails Updated estimate details.
   */
  public updateEstimateDetails(estimateId: EstimateId, updatedEstimateDetails: EditEstimateDetails): Observable<void> {
    return this.http.put<void>(
      this.apiUrls.commonEstimateApi.updateDetails(estimateId),
      this.estimateDetailsMapper.toDto(updatedEstimateDetails),
    );
  }

  /**
   * Save estimate bulk update.
   * @param estimateId Estimate id.
   * @param bulkUpdateOption Bulk update option.
   */
  public saveBulkUpdate(estimateId: EstimateId, bulkUpdateOption: BulkUpdateOption): Observable<void> {
    return this.http.put<void>(
      this.apiUrls.commonEstimateApi.saveBulkUpdate(estimateId),
      { bulkUpdateOption },
    );
  }
}
