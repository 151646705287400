import { Injectable } from '@angular/core';

import { EditEstimateDetails } from '@dartsales/common/core/models/estimate/estimate';

import { IMapperToDto } from '../mappers';
import { EditEstimateDetailsDto } from '../dto/estimate/estimate.dto';

/** Estimate details mapper. */
@Injectable({
  providedIn: 'root',
})
export class EstimateDetailsMapper implements IMapperToDto<EditEstimateDetailsDto, EditEstimateDetails> {

  /** @inheritdoc */
  public toDto(data: EditEstimateDetails): EditEstimateDetailsDto {
    return {
      description: data.description,
      name: data.name,
    };
  }
}
